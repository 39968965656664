'use client'

import { usePathname } from 'next/navigation'
import { useState, useRef, useEffect } from 'react'

import { MOVIE_PAGE_SESSIONS_FILTER_ID } from '@Shared/constants/application'
import { MOVIE } from '@Shared/constants/page-name'
import { eventCategory } from '@Shared/helpers/tracking'
import { cn } from '@Shared/helpers/util'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import Link from '@Components/Link'

type LinkMapper = { href: string; title: string }
type LinkItemProps = {
  href: LinkMapper['href']
  title: string
}

const LinkItem = ({ href, title }: LinkItemProps) => {
  const pathname = usePathname()
  const pageName = usePageName()

  const handleTracking = () => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: `Click on ${pageName} page`,
      event_label: '',
      event_value: '0',
      event_non_interaction: false
    })
  }

  const getPathname = (href) => {
    const baseUrl = process.env.BASE_URL
    const url = new URL(href, baseUrl)

    return url.pathname
  }

  return (
    <li
      className="ml-12 inline-block max-w-[90px] first:ml-[30px]"
      onClick={handleTracking}
    >
      <Link
        href={href}
        aria-label={`Navegar para a página de ${title}`}
        className={cn(
          'flex items-center no-underline hover:opacity-80',
          pathname === getPathname(href)
            ? 'bg-ing-gradient-yellow bg-clip-text text-transparent'
            : 'text-ing-blue-300'
        )}
      >
        {title}
      </Link>
    </li>
  )
}

const linkMapper: LinkMapper[] = [
  { href: '/filmes', title: 'Filmes' },
  { href: '/cinemas', title: 'Cinemas' },
  { href: '/teatros', title: 'Teatro' },
  { href: '/eventos', title: 'Eventos' },
  { href: '/noticias', title: 'Notícias' }
] as const

const LinkList = () => {
  const pageName = usePageName()

  const [isVisible, setIsVisible] = useState(true)

  const previousWinScroll = useRef(0)

  useEffect(() => {
    const scrollHandler = () => {
      const isMoviePage = pageName === MOVIE

      const dateListElement = document.getElementById(
        MOVIE_PAGE_SESSIONS_FILTER_ID
      ) as HTMLDivElement

      const dateListElementTop = dateListElement?.getBoundingClientRect().top

      setIsVisible(!Boolean(isMoviePage && dateListElementTop <= 70))

      if (isMoviePage && dateListElementTop <= 70) {
        return
      }

      const heightToHideFrom = 150
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop

      setIsVisible(
        winScroll <= heightToHideFrom || winScroll < previousWinScroll.current
      )

      previousWinScroll.current = winScroll
    }

    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={cn(
        'z-[1] hidden transition-[all] duration-500 ease-out md:flex md:h-14 md:items-center md:bg-ing-blue-800',
        isVisible ? 'md:visible md:mt-0' : 'md:invisible md:-mt-14'
      )}
      role="region"
    >
      <nav
        className="max-w-screen-xl xl:mx-auto xl:my-0 xl:w-[1440px]"
        aria-labelledby="Menu Principal"
      >
        <ul className="text-base font-bold uppercase">
          {linkMapper.map((link) => (
            <LinkItem href={link.href} title={link.title} key={link.title} />
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default LinkList
