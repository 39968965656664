'use client'

import dynamic from 'next/dynamic'
import { useSearchParams } from 'next/navigation'
import Script from 'next/script'
import { ComponentProps, useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import Button from 'src/ui/button'
import Popover from 'src/ui/popover'

import { eventCategory } from '@Shared/helpers/tracking'
import { cn } from '@Shared/helpers/util'
import useCity from '@Shared/hooks/use-city'
import { usePageName } from '@Shared/hooks/use-page-name'
import useUserData from '@Shared/hooks/use-user-data'

import tracking from '@Services/tracking'

import Icon from '@Components/icon'
import Image from '@Components/Image'
import Link from '@Components/Link'

import LinkList from './link-list'
import Location from './location'
import Login from './login'
import Search from './search'
import Support from './support'
import UserAvatar from './user-avatar'

const HandTalk = dynamic(() => import('@Components/hand-talk'), { ssr: false })

const CONTROL_PANEL_URL = process.env.CONTROL_PANEL_URL

type HeaderProps = ComponentProps<'header'>

const Header = ({ className, ...props }: HeaderProps) => {
  const [loadHelper, setLoadHelper] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [supportOpen, setSupportOpen] = useState(false)
  const [locationOpen, setLocationOpen] = useState(false)

  const searchParams = useSearchParams()
  const searchQuery = searchParams.get('q')
  const cityQuery = searchParams.get('city')

  const { city, isReady } = useCity()
  const { user } = useUserData()
  const pageName = usePageName()
  const isMobile = useMedia('(max-width: 600px)', false)

  const userLoggedIn = Boolean(user?.token)

  const logoImage = isMobile
    ? 'https://ingresso-a.akamaihd.net/catalog/img/ingresso-logo-v1-mobile-final.svg'
    : 'https://ingresso-a.akamaihd.net/catalog/img/ingresso-logo-v1-desktop-final.svg'

  const handleTracking = (action, label) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: `Click on ${action}`,
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  useEffect(() => {
    setSearchOpen(pageName === 'search_result' && !Boolean(searchQuery))
  }, [searchQuery, pageName])

  useEffect(() => {
    return setLocationOpen(cityQuery !== null && !Boolean(cityQuery))
  }, [cityQuery])

  const ariaLabel = {
    editAccount: userLoggedIn
      ? 'Navegar para a página de edição de cadastro'
      : 'Navegar para a página de login',
    userAction: userLoggedIn
      ? 'Abrir tooltip para ações do usuário'
      : 'Abrir tooltip para login ou cadastro'
  }

  const handleLoadHelper = () => {
    setLoadHelper(true)
    setSupportOpen(false)
  }

  return (
    <header
      className={cn('fixed top-0 z-[98] flex w-full flex-col', className)}
      {...props}
    >
      <div className="z-[2] bg-gradient-to-r from-ing-blue to-ing-blue-800 px-3 py-0">
        <div className="mx-auto my-0 flex h-[50px] max-w-screen-xl select-none items-center justify-between md:h-16 xl:px-4">
          <Button
            variant="ghost"
            asChild
            className="overflow-hidden px-0 md:mr-[68px]"
            onClick={() => handleTracking('Logo', '')}
          >
            <Link href="/">
              <div className="relative h-9 w-7 min-[600px]:w-[150px]">
                <Image
                  fill
                  src={logoImage}
                  alt="Logo da Ingresso.com"
                  className="inline-block max-w-none align-baseline"
                  aria-label="Navegar para a página inicial da Ingresso.com"
                />
              </div>
            </Link>
          </Button>
          {isReady && (
            <Popover.Root open={locationOpen} onOpenChange={setLocationOpen}>
              <Popover.Trigger asChild>
                <Button
                  variant="ghost"
                  className="p-0 max-md:h-[30px] max-md:flex-1"
                  onClick={() => {
                    handleTracking('Location', city?.name)
                  }}
                  aria-label="Abrir tooltip para trocar a localização atual"
                >
                  <div className="mt-1 flex items-center md:ml-8">
                    <Icon
                      src="/images/location.svg"
                      className="mx-0 my-auto size-7 fill-ing-blue-200 pr-[6px] md:size-9"
                    />
                    <p className="mx-auto text-[13px] leading-5 text-ing-blue-200 md:text-base">
                      {city?.name}
                    </p>
                  </div>
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <Popover.Arrow />
                <Location />
              </Popover.Content>
            </Popover.Root>
          )}
          <div className="flex items-center justify-between gap-2 md:ml-auto md:gap-3 lg:gap-6">
            <Popover.Root open={searchOpen} onOpenChange={setSearchOpen}>
              <Popover.Trigger asChild>
                <Button
                  variant="ghost"
                  className="p-0 focus-visible:ring-0 max-md:h-[30px] max-md:flex-1"
                  onClick={() => {
                    handleTracking('Header Search', '')
                  }}
                  aria-label="Abrir tooltip para pesquisas no site"
                >
                  <Icon
                    src="/images/search.svg"
                    className="size-6 fill-ing-blue-200 md:size-9"
                  />
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <Popover.Arrow />
                <Search />
              </Popover.Content>
            </Popover.Root>
            <div className="flex items-center">
              <Popover.Root>
                <Popover.Trigger asChild>
                  <Button
                    variant="ghost"
                    className="px-0"
                    onClick={() => {
                      handleTracking('My account', `logged ${userLoggedIn}`)
                    }}
                    aria-label={ariaLabel['userAction']}
                  >
                    {isMobile ? (
                      <Link
                        href={CONTROL_PANEL_URL}
                        openExternalInSameTab
                        aria-label={ariaLabel['editAccount']}
                      >
                        <UserAvatar />
                      </Link>
                    ) : (
                      <UserAvatar />
                    )}
                  </Button>
                </Popover.Trigger>
                {!isMobile && (
                  <Popover.Content>
                    <Popover.Arrow />
                    <Login />
                  </Popover.Content>
                )}
              </Popover.Root>
            </div>
            <Popover.Root open={supportOpen} onOpenChange={setSupportOpen}>
              <Popover.Trigger asChild>
                <Button
                  className="p-0 max-md:h-[30px] max-md:flex-1"
                  variant="ghost"
                  onClick={() => {
                    handleTracking('Support', '')
                  }}
                  aria-label="Abrir tooltip para suporte ao cliente"
                >
                  <Icon
                    src="/images/faq.svg"
                    className="size-6 fill-ing-blue-200 md:size-9"
                  />
                </Button>
              </Popover.Trigger>
              <Popover.Content className="p-4 md:p-6">
                <Popover.Arrow />
                <Support handleLoadHelper={handleLoadHelper} />
              </Popover.Content>
            </Popover.Root>
          </div>
        </div>
      </div>
      <LinkList />
      <HandTalk />
      {loadHelper && (
        <Script
          id="helperPlugin"
          src={process.env.HELPER_URL}
          strategy="lazyOnload"
          data-id="tr457s89"
          data-type="none"
          onReady={() => {
            //TODO: Remove this setTimeout when the helper plugin is fixed
            setTimeout(() => {
              window.openChat()
              setLoadHelper(false)
            }, 1000)
          }}
        />
      )}
    </header>
  )
}

export default Header
